.wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.wrapper > svg, canvas {
    position: fixed;
    bottom: 0;
    /* left: 0; */
    right: 0;
    /* top: 0; */
    z-index: 999;
}

.popover{
    position: fixed;
    z-index: 9999;
    transform: translate(0, -50%);
    padding: 5px;
    font-family: var(--header-font);
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    text-shadow: 0px 0px 20px rgba(0,0,0,0.25);;
}
