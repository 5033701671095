@font-face {
  font-family: "Arial Black";
  src: url("/fonts/ariblkmin/ariblkmin.eot");
  src: url("/fonts/ariblkmin/ariblkmin.eot?#iefix") format("embedded-opentype"),
    url("/fonts/ariblkmin/ariblkmin.woff2") format("woff2"),
    url("/fonts/ariblkmin/ariblkmin.woff") format("woff"),
    url("/fonts/ariblkmin/ariblkmin.ttf") format("truetype"),
    url("/fonts/ariblkmin/ariblkmin.svg#Arial Black") format("svg");
}

@font-face {
  font-family: "Arial Blur";
  src: url("/fonts/ariblur/ariblur.woff2") format("woff2"),
    url("/fonts/ariblur/ariblur.woff") format("woff"),
    url("/fonts/ariblur/ariblur.otf") format("opentype"),
    url("/fonts/ariblur/ariblur.svg#Arial Blur") format("svg");
  font-style: normal;
  font-weight: 400;
}

:root {
  --background-color: #000;
  --body-font: Arial, Helvetica, sans-serif;
  --header-font: "Arial Black", Gadget, sans-serif;
  --title-font: "Arial Blur";
  --link-font: "Arial Narrow", sans-serif;
  --text-accent: #ffffff;
  --bg-accent: #a1c0f4;
  --columns: 4;
  --insetMaxWidth: 60%;
  --page-gutter: 5vw;
}

@custom-media --mobile (max-width: 1080px);
@custom-media --tablet (min-width: 600px);
@custom-media --desktop (min-width: 768px);
@custom-media --huge (min-width: 1500px);
